export default {
  props: {
    value: {
      type: Object
    }
  },

  data () {
    return {
      mutableValue: {}
    }
  },

  watch: {
    value: {
      handler: 'initValue',
      immediate: true
    }
  },

  methods: {
    initValue (val) {
      if (typeof val !== 'undefined' && val !== null) {
        this.mutableValue = JSON.parse(JSON.stringify(val))
      } else {
        this.mutableValue = {}
      }
    },

    cancelHandle () {
      this.$emit('cancel')
    },

    saveHandle () {
      this.checkData().then(() => {
        this.$emit('input', this.mutableValue)
      }).catch(() => {})
    },

    deleteHandle () {
      this.$emit('delete', this.mutableValue.id)
      this.$emit('cancel')
    },

    reloadHandler () {
      this.$emit('reload')
    },

    async checkData () {
      const form = this.$refs.form
      if(form) {
        return await form.validate()
      }
      return true
    }
  }
}
