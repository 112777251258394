<template>
  <mcb-detail-popup class="detail" :title="mutableValue.name" @save="saveHandle" @esc="cancelHandle">
    <el-form ref="form" :rules="rules" :model="mutableValue" label-position="left" label-width="90px">
      <el-form-item label="Название" prop="name">
        <el-input v-model="mutableValue.name" />
      </el-form-item>
    </el-form>
  </mcb-detail-popup>
</template>

<script>
import DetailPage from '@/mixins/detail-page.js'

export default {
  mixins: [DetailPage],

  data () {
    return {
      rules: {
        name: [{ required: true, message: 'Пожалуйчта введите название', trigger: 'blur' }],
      }
    }
  }
}
</script>
